import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Members from "./Components/Members/Members";
import StakingIncome from "./Components/Report/StakingIncome";
import Withdrawal from "./Components/Report/Withdrawal";
import MemberReport from "./Components/Members/MemberReport";
import Login from "./Components/Login";
import LevelIncome from "./Components/Report/LevelIncome";
import RankIncome from "./Components/Report/RankIncome";
import CommunityIncome from "./Components/Report/CommunityIncome";
import RoiOfRoi from "./Components/Report/RoiOfRoi";
import Stakings from "./Components/Report/Stakings";
import Unstake from "./Components/Report/Unstake";
import PendingWithdrawal from "./Components/Report/PendingWithdrawal";
import Silver from "./Components/Ranks/Silver";
import Gold from "./Components/Ranks/Gold";
import Pearl from "./Components/Ranks/Pearl";
import Ruby from "./Components/Ranks/Ruby";
import Emerald from "./Components/Ranks/Emerald";
import Sapphire from "./Components/Ranks/Sapphire";
import Diamond from "./Components/Ranks/Diamond";
import BlueDiamond from "./Components/Ranks/BlueDiamond";
import RedDiamond from "./Components/Ranks/RedDiamond";
import RoyalDiamond from "./Components/Ranks/RoyalDiamond";
import BlockA from "./Components/Block/BlockA";
import BlockB from "./Components/Block/BlockB";
import Cron from "./Components/Cron";
import AddTxs from "./Components/Report/AddTxs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Index />} />
        <Route path="/users" element={<Members />} />
        <Route path="/userreport/:address" element={<MemberReport />} />
        <Route path="/staking-reward" element={<StakingIncome />} />
        <Route path="/aggregator-reward" element={<LevelIncome />} />
        <Route path="/rank-reward" element={<RankIncome />} />
        <Route path="/mining-reward" element={<CommunityIncome />} />
        <Route path="/cold-reward" element={<RoiOfRoi />} />
        <Route path="/stakings" element={<Stakings />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/pending-withdrawal" element={<PendingWithdrawal />} />
        {/* <Route path="/statements" element={<Statements />} /> */}

        {/* rank wise users */}
        <Route path="/silver" element={<Silver />} />
        <Route path="/gold" element={<Gold />} />
        <Route path="/pearl" element={<Pearl />} />
        <Route path="/ruby" element={<Ruby />} />
        <Route path="/emerald" element={<Emerald />} />
        <Route path="/sapphire" element={<Sapphire />} />
        <Route path="/diamond" element={<Diamond />} />
        <Route path="/blue-diamond" element={<BlueDiamond />} />
        <Route path="/red-diamond" element={<RedDiamond />} />
        <Route path="/royal-diamond" element={<RoyalDiamond />} />
        <Route path="/block-a" element={<BlockA />} />
        <Route path="/block-b" element={<BlockB />} />
        <Route path="/crons" element={<Cron />} />
        <Route path="/addtxs" element={<AddTxs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
