import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";

export default function AddTxs({ address }) {
  const { formatAddress, getudata, blocks, web3, contract, formatDateTime } = useContext(GMCContext);

  const [rate, setrate] = useState(1);
  const [tokenrate, settokenrate] = useState(1);
  const [amount, setamount] = useState(0);
  const [maticfee, setmaticfee] = useState(0);
  const [maticfee$, setmaticfee$] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [loader, setloader] = useState(false);
  const [error, seterror] = useState("");
  const [account, setaccount] = useState("");
  const [referral, setreferral] = useState("");
  const [incap, setincap] = useState("3");
  const [incappass, setincappass] = useState("0");
  const [incapper, setincapper] = useState("0");
  const [block, setblock] = useState("A");
  const onDeposit = async () => {
    settrdone(true);

    // sethash("");
    if (!hash) {
      seterror("Enter Hash");
      return;
    }
    if (!account) {
      seterror("Enter Address");
      return;
    }
    if (!referral) {
      seterror("Enter Referral Address");
      settrdone(false);
      return;
    }

    if (!amount || amount < 0) {
      seterror("Enter amount");
      settrdone(false);
      return;
    }
    var data = await getudata(referral);
    console.log('data',data);
    var dbuser = await getudata(account);
    if (data.data.error) {
      seterror("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    if (dbuser !== null && dbuser.last_staking > amount) {
      seterror(`Stake ${dbuser.last_staking} or more`);
      settrdone(false);
      return;
    }


    if (block === "A") {
      if (
        Number(amount) < blocks.minstakeblocka ||
        Number(amount) > blocks.maxstakeblocka
      ) {
        seterror("Enter amount between min and max");
        settrdone(false);
        return;
      }
    } else {
      if (
        Number(amount) < blocks.minstakeblockb ||
        Number(amount) > blocks.maxstakeblockb
      ) {
        seterror("Enter amount between min and max");
        settrdone(false);
        return;
      }
    }
    await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "stake",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            referral_address: referral,
            hash: hash,
            amount: amount,
            token: total_tokens,
            matic: maticfee,
            matic_rate: rate,
            token_rate: tokenrate,
            t_months: block === "A" ? 15 : 24,
            b_type: block === "A" ? 0 : 1,
            incap_per: incapper,
            income_capability: incap,
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              seterror(res.data.error)
              return "";
            }
            seterror("done")
          });

  };
  const setvaluess = async () => {
    if (!contract) {
      return;
    }
    const fmin = await contract.methods.findblockandfee(
      web3.utils.toWei(amount.toString(), 'ether'),
      incappass
    ).call();
    const calcstakingfee = await contract.methods.calcstakingfee(
      web3.utils.toWei(amount.toString(), 'ether'),
      fmin[1]
    ).call();
    setmaticfee(web3.utils.fromWei(calcstakingfee, 'ether'));
    setmaticfee$(web3.utils.fromWei(fmin[2], 'ether'));
    setincapper(web3.utils.fromWei(fmin[1], 'ether'));
  };

  useEffect(() => {
    if (block === "B") {
      if (incap === "3") {
        setincap("4");
      }
    }
  }, [block]);

  useEffect(() => {
    settotal_tokens(amount / tokenrate);
  }, [amount,tokenrate,rate]);
  useEffect(() => {
    setvaluess();
  }, [web3, amount, incap,tokenrate,rate]);

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">Withdrawal</li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Add Transaction</h4>
                  </div>
                  <div className="card-body">
                    <div className="basic-form">
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">Hash</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Hash#" value={hash}
                              onChange={(e) => { sethash(e.target.value); }
                              } />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">Address</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Address" value={account}
                              onChange={(e) => { setaccount(e.target.value); }
                              } />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">Referral Address</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Referral Address" value={referral}
                              onChange={(e) => { setreferral(e.target.value); }
                              } />
                          </div>
                        </div>
                        <fieldset className="mb-3">
                          <div className="row">
                            <label className="col-form-label col-sm-3 pt-0">Block</label>
                            <div className="col-sm-9 d-flex justify-content-around">
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="blockab" value="A" defaultChecked onChange={(e) =>
                                  setblock(e.target.value)
                                } />
                                <label className="form-check-label">
                                  Block A
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="blockab" value="B" onChange={(e) =>
                                  setblock(e.target.value)
                                } />
                                <label className="form-check-label">
                                  Block B
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="mb-3 row">
                          <div className="col-12 text-center">
                            <p>
                              {block === "A"
                                ? `Min: $ ${blocks !== null
                                  ? blocks.minstakeblocka
                                  : 0
                                } Max $ ${blocks !== null
                                  ? blocks.maxstakeblocka
                                  : 0
                                }`
                                : `Min: $ ${blocks !== null
                                  ? blocks.minstakeblockb
                                  : 0
                                } Max $ ${blocks !== null
                                  ? blocks.maxstakeblockb
                                  : 0
                                }`}
                            </p>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">Amount</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Enter Amount" value={amount}
                              onChange={(e) =>
                                setamount(e.target.value)
                              } />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">Token Rate</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Enter Token Rate" value={tokenrate}
                              onChange={(e) =>
                                settokenrate(e.target.value)
                              } />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-sm-3 col-form-label">BNB Rate</label>
                          <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Enter BNB Rate" value={rate}
                              onChange={(e) =>
                                setrate(e.target.value)
                              } />
                          </div>
                        </div>
                        <fieldset className="mb-3">
                          <div className="row">
                            <label className="col-form-label col-sm-3 pt-0">x  {block === "A" ? "3x" : "4x"}</label>
                            <div className="col-sm-9 d-flex justify-content-around">
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="x43" value={block === "A" ? "3" : "4"}
                                  defaultChecked={true}
                                  onChange={(e) => {
                                    setincap(e.target.value);
                                    setincappass("0");
                                  }} />
                                <label className="form-check-label">
                                  3x
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="x43" value="6"
                                  onChange={(e) => {
                                    setincap(e.target.value);
                                    setincappass("1");
                                  }} />
                                <label className="form-check-label">
                                  6x
                                </label>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div className="mb-3 row">
                          <div className="col-sm-10">
                            <div className="pt-3 text-light text-center">
                              Stake : {total_tokens}{" "}
                              {process.env.REACT_APP_TICKER}
                            </div>
                            <div className="text-light text-center">
                              Token Rate : ${tokenrate}
                            </div>
                            <div className="text-light text-center">
                              Rate : ${rate} / 1 BNB
                            </div>
                            <div className="text-light text-center">
                              Fee : {Number(maticfee).toFixed(2)} BNB ($
                              {maticfee$})
                            </div>

                          </div>
                        </div>
                        <div className="mb-3 row">
                            <span className="text-center text-danger">
                              {error}
                            </span>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-10">
                            <button className="btn btn-primary" onClick={()=>onDeposit()}>Add Transaction</button>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
