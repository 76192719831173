import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import parse from "html-react-parser";
import { GMCContext } from "../../GMCContext";
import { FaUsers } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { PiCoinsLight } from "react-icons/pi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiRank3 } from "react-icons/gi";
import { TbBuildingCommunity } from "react-icons/tb";
import { MdOutlinePendingActions } from "react-icons/md";
import { IoIosList } from "react-icons/io";
import { GiMining } from "react-icons/gi";
import { WiSnowflakeCold } from "react-icons/wi";
import { GrStakeholder } from "react-icons/gr";
import { FaCubes } from "react-icons/fa";

export default function Sidebar() {
  const { setToggleAchieveRank, toggleAchieveRank } = useContext(GMCContext);
  const epoint = useLocation().pathname;

  const clearToggle = () => {
    setToggleAchieveRank("");
  };

  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li className={`${epoint === "/dashboard" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link className={`ai-icon `} to="/dashboard" aria-expanded="false">
                <RxDashboard color="white" size="1.2rem" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/users" || epoint.includes("/userreport") ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/users" className="ai-icon" aria-expanded="false">
                <FaUsers color="white" size="1.2rem" />
                <span className="nav-text">Users</span>
              </Link>
            </li>
            <li className={toggleAchieveRank === "achieve-rank" ? "mm-active" : ""}>
              <Link
                className="has-arrow ai-icon"
                onClick={() => setToggleAchieveRank(toggleAchieveRank === "achieve-rank" ? "" : "achieve-rank")}
              >
                <FaRankingStar color="white" size="1.2rem" />
                <span className="nav-text">Achieve Rank</span>
              </Link>
              <ul style={toggleAchieveRank === "achieve-rank" ? {} : { height: "16px", display: "none" }}>
                <li className={`${epoint === "/silver" ? "mm-active" : ""}`}>
                  <Link to="/silver" className="ai-icon">
                    <span className="nav-text">Silver</span>
                  </Link>
                </li>
                <li className={`${epoint === "/gold" ? "mm-active" : ""}`}>
                  <Link to="/gold" className="ai-icon">
                    <span className="nav-text">Gold</span>
                  </Link>
                </li>
                <li className={`${epoint === "/pearl" ? "mm-active" : ""}`}>
                  <Link to="/pearl" className="ai-icon">
                    <span className="nav-text">Pearl</span>
                  </Link>
                </li>
                <li className={`${epoint === "/ruby" ? "mm-active" : ""}`}>
                  <Link to="/ruby" className="ai-icon">
                    <span className="nav-text">Ruby</span>
                  </Link>
                </li>
                <li className={`${epoint === "/emerald" ? "mm-active" : ""}`}>
                  <Link to="/emerald" className="ai-icon">
                    <span className="nav-text">Emerald</span>
                  </Link>
                </li>
                <li className={`${epoint === "/sapphire" ? "mm-active" : ""}`}>
                  <Link to="/sapphire" className="ai-icon">
                    <span className="nav-text">Sapphire</span>
                  </Link>
                </li>
                <li className={`${epoint === "/diamond" ? "mm-active" : ""}`}>
                  <Link to="/diamond" className="ai-icon">
                    <span className="nav-text">Diamond</span>
                  </Link>
                </li>
                <li className={`${epoint === "/blue-diamond" ? "mm-active" : ""}`}>
                  <Link to="/blue-diamond" className="ai-icon">
                    <span className="nav-text">Blue Diamond</span>
                  </Link>
                </li>
                <li className={`${epoint === "/red-diamond" ? "mm-active" : ""}`}>
                  <Link to="/red-diamond" className="ai-icon">
                    <span className="nav-text">Red Diamond</span>
                  </Link>
                </li>
                <li className={`${epoint === "/royal-diamond" ? "mm-active" : ""}`}>
                  <Link to="/royal-diamond" className="ai-icon">
                    <span className="nav-text">Royal Diamond</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={toggleAchieveRank === "block" ? "mm-active" : ""}>
              <Link
                className="has-arrow ai-icon"
                onClick={() => setToggleAchieveRank(toggleAchieveRank === "block" ? "" : "block")}
              >
                <FaCubes color="white" size="1.2rem" />
                <span className="nav-text">Block</span>
              </Link>
              <ul style={toggleAchieveRank === "block" ? {} : { height: "16px", display: "none" }}>
                <li className={`${epoint === "/block-a" ? "mm-active" : ""}`}>
                  <Link to="/block-a" className="ai-icon">
                    <span className="nav-text">Block A</span>
                  </Link>
                </li>
                <li className={`${epoint === "/block-b" ? "mm-active" : ""}`}>
                  <Link to="/block-b" className="ai-icon">
                    <span className="nav-text">Block B</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${epoint === "/staking-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/staking-reward" className="ai-icon" aria-expanded="false">
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Staking Reward</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/aggregator-reward" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/aggregator-reward" className="ai-icon" aria-expanded="false">
                <FaMoneyBillTrendUp color="white" size="1.2rem" />
                <span className="nav-text">Aggregator Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/rank-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/rank-reward" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Rank Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/mining-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/mining-reward" className="ai-icon" aria-expanded="false">
                <GiMining color="white" size="1.2rem" />
                <span className="nav-text">Mining Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/cold-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/cold-reward" className="ai-icon" aria-expanded="false">
                <WiSnowflakeCold color="white" size="1.2rem" />
                <span className="nav-text">Cold Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/stakings" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/stakings" className="ai-icon" aria-expanded="false">
                <GrStakeholder color="white" size="1.2rem" />
                <span className="nav-text">Stakings</span>
              </Link>
            </li>
            <li className={`${epoint === "/unstake" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/unstake" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-id-card-4" />
                <span className="nav-text">Unstake</span>
              </Link>
            </li>
            <li className={`${epoint === "/withdrawal" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-gift" />
                <span className="nav-text">Withdrawal</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/pending-withdrawal" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/pending-withdrawal" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Pending-Withdrawal</span>
              </Link>
            </li>
            <li className={`${epoint === "/addtxs" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}>
              <Link to="/addtxs" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Add Transaction</span>
              </Link>
            </li>
            <li className={`${epoint === "/crons" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/crons" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Crons</span>
              </Link>
            </li>
            {/* <li className={`${epoint === "/statements" ? "mm-active active-no-child" : ""}`}>
              <Link to="/statements" className="ai-icon" aria-expanded="false">
                <IoIosList color="white" size="1.2rem" />
                <span className="nav-text">Statements</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
